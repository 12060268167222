<div fxLayout="column" fxFlexAlign="center" fxLayoutGap="1rem">
  <img src="/assets/images/logo.png" />
  <p>ただいまメンテナンス中です。</p>
  <div
    class="detail"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="0.8rem"
  >
    <p>メンテナンス期間</p>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div class="start">
        {{ status.schedule_start_date | date: 'MM/dd HH:mm' }}
      </div>
      <div>～</div>
      <div class="end">
        <ng-container *ngIf="!!status.schedule_end_date">
          {{ status.schedule_end_date | date: 'MM/dd HH:mm' }}
        </ng-container>
        <ng-container *ngIf="!status.schedule_end_date"> 未定 </ng-container>
      </div>
    </div>
  </div>
  <p class="note">
    終了予定時刻は、作業の状況により変更される場合がございます。<br />
    ご迷惑をおかけいたしますが、ご理解賜りますようお願い申し上げます。
  </p>
</div>
