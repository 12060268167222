import { Campaign } from './campaign';
import { Influencer } from './influencer';
import { Type } from 'class-transformer';
import { InstagramPost } from './social-account/instagram';

export class PostingReport {
  @Type(() => Campaign)
  campaign?: Campaign;
  @Type(() => Influencer)
  influencer?: Influencer;

  id!: number;
  campaign_id!: number;
  influencer_id!: number;
  post_id!: string;
  remanded: boolean | null = null;
  snapshot?: InstagramPost;
}
