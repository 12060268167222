import { Performance } from './performance';

export abstract class SocialAccount<T extends Performance> {
  influencer_id!: number;
  performances!: T[];

  latestPerformance(): T | undefined {
    const sortedKeys = this.performances.map(performance => performance.collected_at).sort();
    return this.performances.find(performance => performance.collected_at === sortedKeys[sortedKeys.length - 1]);
  }

  findPerformanceByCollectedAt(collected_at: any): T | undefined {
    return this.performances.find(p => p.collected_at === collected_at);
  }
}
