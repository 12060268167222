import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL, WINDOW } from '@cuepid/core';
import { environment } from '@cuepid/influencer/env';
import Auth0Lock from 'auth0-lock';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly lock: Auth0LockStatic;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    @Inject(DOCUMENT) private readonly document: Document,
    private http: HttpClient
  ) {
    this.lock = new Auth0Lock(
      environment.auth.clientId,
      environment.auth.domain,
      {
        allowForgotPassword: true,
        allowShowPassword: true,
        configurationBaseUrl: environment.auth.configurationBaseUrl,
        defaultDatabaseConnection: environment.auth.defaultDatabaseConnection,

        auth: {
          ...environment.auth.lockAuthOptions,
          responseMode: 'query',
          responseType: 'code',
          redirect: true,
          params: {
            protocol: 'oauth2',
            scope: 'openid profile email offline_access',
          },
          sso: false,
        },

        language: 'ja',
        languageDictionary: {
          title: 'ログイン',
          signUpTerms:
            '<a class="show-terms" href="/information/terms" target="_blank" rel="noreferrer noopener">利用規約</a> に同意する',
        },
        theme: {
          logo: '/assets/images/logo.png',
        },
        mustAcceptTerms: true,
        // @ts-ignore: typesがまだ更新されていないため
        showTerms: true,

        additionalSignUpFields: [
          {
            name: 'email_confirm',
            placeholder: 'メールアドレス（確認用）',
            icon: '/assets/images/icon/icon-web-app.png',
            validator: (confirm) => {
              const email = this.document.querySelector(
                'input[name="email"]'
              ) as HTMLInputElement;
              return {
                valid: email?.value === confirm,
                hint: '異なるメールアドレスが入力されています',
              };
            },
          },
        ],
      }
    );

    let obs: MutationObserver;
    this.lock.on('show', () => {
      this.switchInputFormOrder();
      const form = this.document.querySelector('.auth0-lock-form') as Element;
      obs = new MutationObserver(() => this.switchInputFormOrder());
      obs.observe(form, {
        attributes: false,
        characterData: false,
        childList: true,
      });
    });

    this.lock.on('hide', () => obs?.disconnect());
  }

  signIn() {
    this.lock.show({
      initialScreen: 'login',
    });
  }

  signUp() {
    this.lock.show({
      initialScreen: 'signUp',
    });
  }

  signOut() {
    this.window.location.href = `${this.apiBaseUrl}/auth/influencer/signOut`;
  }

  verifyToken() {
    return this.http.get(`${this.apiBaseUrl}/auth/influencer/verify`);
  }

  private switchInputFormOrder() {
    const confirm = this.window.document.querySelector(
      '.auth0-lock-input-email_confirm'
    ) as Element | null;
    if (!confirm) {
      return;
    }

    const password = this.window.document.querySelector(
      '.auth0-lock-input-show-password'
    ) as Element;
    confirm.parentNode?.insertBefore(confirm, password);
  }
}
