<div fxLayout="row">
  <div class="close-menu" (click)="closeSideBar.emit()">
    <div class="material-icons-outlined icon-hamburger">menu</div>
  </div>
  <div fxFlex="1fr" fxLayout="column" class="sidebar">
    <mat-nav-list>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'information', 'news']"
        routerLinkActive="active"
      >
        <i class="material-icons-outlined icon-menu">chat_bubble</i>
        <span>新着情報</span>
      </a>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'user', 'profile']"
        routerLinkActive="active"
      >
        <i class="material-icons icon-menu">person</i>
        <span>プロフィール</span>
      </a>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'user', 'personal']"
        routerLinkActive="active"
      >
        <i class="material-icons-outlined icon-menu">credit_score</i>
        <span>個人情報管理</span>
      </a>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'report']"
        routerLinkActive="active"
      >
        <i class="material-icons-outlined icon-menu">insights</i>
        <span>レポート</span>
      </a>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'support', 'qa']"
        routerLinkActive="active"
      >
        <i class="material-icons-outlined icon-menu">help</i>
        <span>Q&A</span>
      </a>
      <a
        class="menu-content"
        mat-list-item
        [routerLink]="['/', 'setting']"
        routerLinkActive="active"
      >
        <i class="material-icons-outlined icon-menu">settings</i>
        <span>設定</span>
      </a>
      <mat-list-item (click)="signOut()" class="menu-content">
        <i class="material-icons-outlined icon-menu">logout</i>
        <span>ログアウト</span>
      </mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list class="terms">
      <a
        class="item-line explanation"
        mat-list-item
        [routerLink]="['/', 'privacy']"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span class="no-icon">プライバシーポリシー</span>
        <i class="material-icons-outlined new-tab">open_in_new</i>
      </a>
      <a
        class="explanation"
        mat-list-item
        [routerLink]="['/', 'information', 'terms']"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span class="no-icon">サービス利用規約</span>
        <i class="material-icons-outlined new-tab">open_in_new</i>
      </a>
    </mat-nav-list>
  </div>
</div>
