import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appCampaignListRowNotificationBar]',
})
export class CampaignListRowNotificationBarDirective {
  type: 'alert' | '' = '';

  @Input() set appCampaignListRowNotificationBar(type: 'alert' | '') {
    this.type = type;
  }

  constructor(public readonly templateRef: TemplateRef<any>) {}
}
