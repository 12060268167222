import {
  HttpBackend,
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthInterceptor } from '@cuepid/influencer/core/interceptors/auth.interceptor';
import { Observable } from 'rxjs';

/**
 * 公式のHttpInterceptingHandlerによるInterceptorのラッピング処理を基に、
 * 通信時にAuthInterceptor以外のInterceptorを使うハンドラーを定義
 *
 * @see https://github.com/angular/angular/blob/master/packages/common/http/src/module.ts
 */
@Injectable({ providedIn: 'root' })
export class CustomHttpInterceptingHandler implements HttpHandler {
  private chain: HttpHandler | null = null;

  constructor(private backend: HttpBackend, private injector: Injector) {}

  static createHttpClient(injector: Injector): HttpClient {
    const handler = injector.get(CustomHttpInterceptingHandler);
    return new HttpClient(handler);
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      this.chain = this.createInterceptorChain();
    }
    return this.chain.handle(req);
  }

  private createInterceptorChain(): HttpHandler {
    const interceptors = this.injector.get(HTTP_INTERCEPTORS, []);
    return interceptors
      .filter((interceptor) => !(interceptor instanceof AuthInterceptor))
      .reduceRight(
        (next, interceptor): HttpHandler => ({
          handle: (req: HttpRequest<any>): Observable<HttpEvent<any>> =>
            interceptor.intercept(req, next),
        }),
        this.backend
      );
  }
}
