import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfTokenInterceptor implements HttpInterceptor {

  constructor(private readonly tokenService: HttpXsrfTokenExtractor) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    if (!token) {
      return next.handle(request);
    }

    return next.handle(request.clone({
      headers: request.headers.append('X-XSRF-TOKEN', token),
    }));
  }

}
