import { Contract, ContractPlan } from '@cuepid/core';
import { Expose, Transform, Type } from 'class-transformer';
import { addMonths, isAfter, parseISO, startOfMonth } from 'date-fns';

export class ContractChangeRequest {
  @Expose({ name: 'requested_at', toClassOnly: true })
  @Transform((date) => parseISO(date), { toClassOnly: true })
  requestedAt!: Date;

  @Expose({ name: 'contract_plan_id', toClassOnly: true })
  contractPlanId!: number;

  @Expose({ name: 'apply_timing', toClassOnly: true })
  applyTiming!: number;

  get planStartDate(): Date {
    const now = new Date();
    if (this.applyTiming === Contract.StartTiming.NOW) {
      return now;
    }

    const tmp = addMonths(startOfMonth(this.requestedAt), 1);
    return isAfter(now, tmp) ? now : tmp;
  }
}

export class CompanyMetadataContent {
  @Type(() => ContractChangeRequest)
  contract_change_request: ContractChangeRequest | null = null;
}

export class CompanyMetadata {
  @Expose({ name: 'change_requested_plan', toClassOnly: true })
  changeRequestedPlan: ContractPlan | null = null;
  @Type(() => CompanyMetadataContent)
  content!: CompanyMetadataContent;
}
