import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorToastInterceptor implements HttpInterceptor {
  private static enabled = true;

  constructor(private toastrService: ToastrService) {}

  public static disableOnce(): void {
    ErrorToastInterceptor.enabled = false;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && ErrorToastInterceptor.enabled) {
          if (err.status >= 500) {
            this.toastrService.error(
              '予期せぬエラーが発生しました。申し訳ありませんが、時間を改めて再度お試しください。'
            );
          } else if (err.status >= 400) {
            this.toastrService.error(
              `処理中にエラーが発生しました。(コード: ${err.status})`
            );
          }
        }
        if (!ErrorToastInterceptor.enabled) {
          ErrorToastInterceptor.enabled = true;
        }
        return throwError(err);
      })
    );
  }
}
