import { Offer } from './offer';
import { Type } from 'class-transformer';
import { Entry } from './entry';
import { PostingReport } from './posting-report';

interface Attachment {
  url: string;
  type: 'image' | 'video';
}

type SystemMessageCode = null | '0001' | '0002' | '0003' | '0004' | '0005';

export class Message {
  @Type(() => Offer)
  offer?: Offer;
  @Type(() => Entry)
  entry: Entry | null = null;
  posting_report: PostingReport | null = null;

  id!: number;
  company_id: number | null = null;
  influencer_id: number | null = null;
  posting_report_id: number | null = null;
  title: string | null = null;
  body?: string;
  system_message_code: SystemMessageCode = null;
  created_at!: any;
  attachments?: Attachment[];
}
