import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignListRowMetaInfoDirective } from '@cuepid/influencer/shared/directives/campaign-list-row-meta-info.directive';
import { CampaignListRowNotificationBarDirective } from '@cuepid/influencer/shared/directives/campaign-list-row-notification-bar.directive';

const directives = [
  CampaignListRowMetaInfoDirective,
  CampaignListRowNotificationBarDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
// @ts-ignore:
export class SharedDirectivesModule {}
