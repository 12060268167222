import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import {
  CuepidCoreModule,
  ErrorToastInterceptor,
  GoogleMapService,
  MAINTENANCE_OPTION,
} from '@cuepid/core';
import { CustomHttpInterceptingHandler } from '@cuepid/influencer/core/handler/custom-http-intercepting-handler';
import { HTTP_CLIENT_WITHOUT_AUTH } from '@cuepid/influencer/core/injection-tokens';
import { AuthInterceptor } from '@cuepid/influencer/core/interceptors/auth.interceptor';
import { LoadIndicatorInterceptor } from '@cuepid/influencer/core/interceptors/load-indicator.interceptor';
import { XsrfTokenInterceptor } from '@cuepid/influencer/core/interceptors/xsrf-token.interceptor';
import { environment } from '@cuepid/influencer/env';
import * as Sentry from '@sentry/angular';
import { Angulartics2Module } from 'angulartics2';
import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { appRouterConfig } from './app.routing';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';

@NgModule({
  declarations: [AppComponent, MaintenanceComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CuepidCoreModule.forRoot({
      apiBaseUrl: environment.apiURL,
      googleMapApiKey: environment.googleMapApiKey,
    }),
    HttpClientModule,
    SharedComponentsModule,
    RouterModule.forRoot(appRouterConfig, { scrollPositionRestoration: 'top' }),
    ToastrModule.forRoot({ closeButton: true }),
    MatDialogModule,
    Angulartics2Module.forRoot({ developerMode: !environment.production }),
    ReactiveFormsModule,
    FlexModule,
  ],
  providers: [
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router], useValue: undefined },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorToastInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: LOCALE_ID, useValue: 'ja' },
    {
      provide: HTTP_CLIENT_WITHOUT_AUTH,
      useFactory: CustomHttpInterceptingHandler.createHttpClient,
      deps: [Injector],
    },
    { provide: MAINTENANCE_OPTION, useValue: environment.maintenance },
    GoogleMapService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
    registerLocaleData(localeJa);
  }
}
