import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {

  @Input() title?: string;

  constructor(private route: ActivatedRoute) {
    route.data.subscribe(data => this.title = data?.title);
  }

  ngOnChanges() {
  }

}
