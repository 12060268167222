import { Type } from 'class-transformer';
import { Campaign } from './campaign';
import { Influencer } from './influencer';
import { InstagramPerformance } from './social-account/instagram';

type Status = 'CREATED' | 'DECLINED' | 'ACCEPTED' | 'CANCELED' | 'COMPLETED';

export class Entry {
  @Type(() => Influencer)
  influencer!: Influencer;
  @Type(() => Campaign)
  campaign: Campaign | null = null;
  @Type(() => InstagramPerformance)
  target_instagram_performance?: InstagramPerformance;

  id!: number;
  campaign_id!: number;
  influencer_id!: number;
  target_date!: string;
  accepted_at: string | null = null;
  notified_at: string | null = null;
  declined_at?: string;
  canceled_at?: string;
  completed_at?: string;
  favorite = false;
  shipped_at: string | null = null;
  reward_amount?: number;
  appeal?: string;
  created_at!: string;

  status!: Status;
  notified!: boolean;
}
