<footer>
  <nav fxLayout="row" fxLayoutAlign="center space-around">
    <a [routerLink]="['/', 'dashboard']" routerLinkActive="active">
      <img src="/assets/images/menu/home.png" alt="ホーム"/>
      <div class="title">ホーム</div>
    </a>
    <a [routerLink]="['/', 'campaign', 'search']" routerLinkActive="active">
      <img src="/assets/images/menu/search.png" alt="さがす"/>
      <div class="title">さがす</div>
    </a>
    <a [routerLink]="['/', 'campaign', 'management']" routerLinkActive="active">
      <img src="/assets/images/menu/management.png" alt="案件管理"/>
      <div class="title">キャンペーン管理</div>
    </a>
    <a class="hide-badge-text" [routerLink]="['/', 'message']" routerLinkActive="active" [matBadgeHidden]="!hasUnread"
       matBadge="&#8288;" matBadgeSize="small" matBadgeColor="warn">
      <img src="/assets/images/menu/message.png" alt="メッセージ"/>
      <div class="title">メッセージ</div>
    </a>
  </nav>
</footer>
