export class Medium {
  static readonly INSTAGRAM = new Medium(1, 'Instagram');

  constructor(public readonly id: number, public readonly name: string) {
  }

  static all(): Medium[] {
    return [
      this.INSTAGRAM,
    ];
  }
}
