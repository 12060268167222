export class PartnerOption {
  static readonly EXISTS = new PartnerOption(1, '既婚');
  static readonly NOT_EXISTS = new PartnerOption(2, '未婚');

  constructor(public readonly code: number, public readonly name: string) {
  }

  static all(): PartnerOption[] {
    return [
      PartnerOption.EXISTS,
      PartnerOption.NOT_EXISTS,
    ];
  }

  static fromCode(code: number): PartnerOption | undefined {
    return this.all().find(option => option.code === code);
  }
}
