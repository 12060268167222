import { Type } from 'class-transformer';
import { Campaign } from './campaign';
import { Influencer } from './influencer';
import { InstagramPerformance } from './social-account/instagram';

export class OfferPivot {
  @Type(() => InstagramPerformance)
  target_instagram_performance?: InstagramPerformance;

  offer_id!: number;
  influencer_id!: number;
  target_date!: string;
  reward_amount?: number;
  accepted_at?: string;
  declined_at?: string;
  completed_at?: string;
  shipped_at: string | null = null;

  status!: 'CREATED' | 'ACCEPTED' | 'DECLINED' | 'COMPLETED';
}

export class InfluencerOffer extends Influencer {
  @Type(() => OfferPivot)
  pivot!: OfferPivot;
}

export class Offer {
  @Type(() => InfluencerOffer)
  influencers: InfluencerOffer[] = [];
  @Type(() => Campaign)
  campaign?: Campaign;

  id!: number;
  campaign_id!: number;
  message!: string;
  created_at!: string;
}
