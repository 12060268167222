import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_BASE_URL} from '../cuepid-core.module';
import {Observable} from 'rxjs';
import {Channel} from '../models/channel';

@Injectable({
  providedIn: 'root'
})
export class DirectMessageService {

  constructor(@Inject(API_BASE_URL) protected apiBaseUrl: string, protected http: HttpClient) {
  }

  getChannel(id: number): Observable<Channel> {
    return this.http.get<Channel>(`${this.apiBaseUrl}/channel/${id}`);
  }
}
