import { ValidatorFn } from '@angular/forms';

export class PhoneNumberValidator {
  static get get(): ValidatorFn {
    return control => {
      const {value} = control;

      if (!value) {
        return null;
      }

      if (value.split('-').length !== 3) {
        return {phoneNumberSeparator: true};
      }

      if (/^0\d-\d{4}-\d{4}/.test(value)) {
        // 市外局番2桁、市内局番4桁
        return null;
      }

      if (/^0\d{2}-\d{3,4}-\d{4}/.test(value)) {
        // 市外局番3桁、市内局番3桁
        // 携帯電話
        return null;
      }

      if (/^0\d{3}-\d{2}-\d{4}/.test(value)) {
        // 市外局番4桁、市内局番2桁
        return null;
      }

      if (/^0\d{4}-\d-\d{4}/.test(value)) {
        // 市外局番5桁、市内局番1桁
        return null;
      }

      return {phoneNumberPattern: true};
    };
  }
}
