import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '../models/category';
import { API_BASE_URL } from '../cuepid-core.module';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, @Inject(API_BASE_URL) private apiBaseUrl: string) {
  }

  all(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.apiBaseUrl}/category`);
  }
}
