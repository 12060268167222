import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdministrationService,
  CheckMaintenanceModeGuard as BaseGuard,
} from '@cuepid/core';

@Injectable({
  providedIn: 'root',
})
export class CheckMaintenanceModeGuard extends BaseGuard {
  constructor(administrationService: AdministrationService, router: Router) {
    super(administrationService, router);
  }

  resolveMaintenancePagePath(): string[] {
    return ['/', 'maintenance'];
  }
}
