import { Type } from 'class-transformer';
import { Category } from './category';
import { Channel } from './channel';
import { Entry } from './entry';
import { InstagramAccount } from './social-account/instagram';

export class Influencer {
  @Type(() => InstagramAccount)
  instagram_account?: InstagramAccount;
  @Type(() => Influencer.Settings)
  settings?: Influencer.Settings;

  id!: number;
  username!: string;
  email!: string;
  biography?: string;
  birthday?: any;
  sex?: number;
  prefecture?: number;
  job_title?: string;
  married?: number;
  with_children?: number;
  belongs_agency?: number;

  categories: Category[] = [];

  entries?: Entry[] = [];
  channels?: Channel[] = [];
  bank_account?: Influencer.BankAccount;
  shipping_address?: Influencer.ShippingAddress;

  icon_url?: string;
  working_count?: number;
  profile_fill_rate?: number;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Influencer {
  export interface BankAccount {
    bank_name: string;
    branch_name: string;
    account_type: number;
    account_number: number;
    account_holder: string;
  }

  export interface ShippingAddress {
    name: string;
    name_kana: string;
    phone_number: string;
    postcode: string;
    prefecture: number;
    city: string;
    house_number: string;
    building?: string;
    influencer?: Influencer;
  }

  export class EmailSettings {
    new_arrival: boolean | null = null;
    weekly_campaign_report: boolean | null = null;

    static create(obj: { [key: string]: any }): EmailSettings {
      const tmp = new EmailSettings();
      Object.keys(obj)
        .filter((key) => key in tmp)
        .forEach((key) => (tmp[key as keyof EmailSettings] = obj[key]));
      return tmp;
    }

    isRequiredUpdate(): boolean {
      return (
        typeof this.new_arrival !== 'boolean' ||
        typeof this.weekly_campaign_report !== 'boolean'
      );
    }

    makeEnableToNull(): void {
      if (this.new_arrival === null) {
        this.new_arrival = true;
      }

      if (this.weekly_campaign_report === null) {
        this.weekly_campaign_report = true;
      }
    }
  }

  export class TutorialSettings {
    completed?: boolean;
  }

  export class Settings {
    @Type(() => EmailSettings)
    email?: EmailSettings;

    @Type(() => TutorialSettings)
    tutorial?: TutorialSettings;

    negative?: boolean;
  }
}
