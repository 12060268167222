export class AgencyOption {
  static readonly BELONGS = new AgencyOption(1, '所属');
  static readonly NOT_BELONGS = new AgencyOption(2, '未所属');

  constructor(public readonly code: number, public readonly name: string) {
  }

  static all(): AgencyOption[] {
    return [
      AgencyOption.BELONGS,
      AgencyOption.NOT_BELONGS,
    ];
  }

  static fromCode(code: number): AgencyOption | undefined {
    return this.all().find(option => option.code === code);
  }
}
