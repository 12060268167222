// @dynamic
export class Prefecture {
  private constructor(public readonly code: number, public readonly name: string, public readonly name_alpha: string[]) {
  }

  static all(): Prefecture[] {
    return [
      new Prefecture(1, '北海道', ['hokkaido', 'hokkaidō']),
      new Prefecture(2, '青森県', ['aomori']),
      new Prefecture(3, '岩手県', ['iwate']),
      new Prefecture(4, '宮城県', ['miyagi']),
      new Prefecture(5, '秋田県', ['akita']),
      new Prefecture(6, '山形県', ['yamagata']),
      new Prefecture(7, '福島県', ['fukushima', 'hukusima']),
      new Prefecture(8, '茨城県', ['ibaraki']),
      new Prefecture(9, '栃木県', ['tochigi', 'totigi']),
      new Prefecture(10, '群馬県', ['gunma']),
      new Prefecture(11, '埼玉県', ['saitama']),
      new Prefecture(12, '千葉県', ['chiba', 'tiba']),
      new Prefecture(13, '東京都', ['tokyo', 'tōkyō']),
      new Prefecture(14, '神奈川県', ['kanagawa']),
      new Prefecture(15, '新潟県', ['niigata']),
      new Prefecture(16, '富山県', ['toyama']),
      new Prefecture(17, '石川県', ['ishikawa', 'isikawa']),
      new Prefecture(18, '福井県', ['fukui', 'hukui']),
      new Prefecture(19, '山梨県', ['yamanashi', 'yamanasi']),
      new Prefecture(20, '長野県', ['nagano']),
      new Prefecture(21, '岐阜県', ['gifu', 'gihu']),
      new Prefecture(22, '静岡県', ['shizuoka', 'sizuoka']),
      new Prefecture(23, '愛知県', ['aichi', 'aiti']),
      new Prefecture(24, '三重県', ['mie']),
      new Prefecture(25, '滋賀県', ['shiga', 'siga']),
      new Prefecture(26, '京都府', ['kyoto', 'kyōto']),
      new Prefecture(27, '大阪府', ['osaka', 'ōsaka']),
      new Prefecture(28, '兵庫県', ['hyogo', 'hyōgo']),
      new Prefecture(29, '奈良県', ['nara']),
      new Prefecture(30, '和歌山県', ['wakayama']),
      new Prefecture(31, '鳥取県', ['tottori']),
      new Prefecture(32, '島根県', ['shimane', 'simane']),
      new Prefecture(33, '岡山県', ['okayama']),
      new Prefecture(34, '広島県', ['hiroshima', 'hirosima']),
      new Prefecture(35, '山口県', ['yamaguchi', 'yamaguti']),
      new Prefecture(36, '徳島県', ['tokushima', 'tokusima']),
      new Prefecture(37, '香川県', ['kagawa']),
      new Prefecture(38, '愛媛県', ['ehime']),
      new Prefecture(39, '高知県', ['kochi', 'kōti']),
      new Prefecture(40, '福岡県', ['fukuoka', 'hukuoka']),
      new Prefecture(41, '佐賀県', ['saga']),
      new Prefecture(42, '長崎県', ['nagasaki']),
      new Prefecture(43, '熊本県', ['kumamoto']),
      new Prefecture(44, '大分県', ['oita', 'ōita']),
      new Prefecture(45, '宮崎県', ['miyazaki']),
      new Prefecture(46, '鹿児島県', ['kagoshima', 'kagosima']),
      new Prefecture(47, '沖縄県', ['okinawa']),
    ];
  }

  static fromCode(code: number): Prefecture | undefined {
    return Prefecture.all().find(prefecture => prefecture.code === code);
  }

  static fromName(name: string): Prefecture | undefined {
    return Prefecture.all().find(prefecture => prefecture.name === name);
  }

  static fromNameAlpha(name: string): Prefecture | undefined {
    return Prefecture.all().find(prefecture => prefecture.name_alpha.includes(name.toLowerCase()));
  }
}
