import { routingInstrumentation } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

export const environment = {
  production: true,
  apiURL: 'https://influencer.cuepid.net/api',
  googleMapApiKey: 'AIzaSyB5jjPFip-qt3xdKrRyJzdz2JX4TjJS-Tk',

  auth: {
    clientId: 'eBUycvwTYejYHz2MhTbAp5pfABciY2Bz',
    domain: 'auth.cuepid.net',
    configurationBaseUrl: 'https://cuepid.jp.auth0.com',
    defaultDatabaseConnection: 'Influencers',

    lockAuthOptions: {
      redirectUrl:
        'https://influencer.cuepid.net/api/auth/influencer/signIn/callback',
      audience: 'https://api.cuepid.net',
    },
  },

  maintenance: {
    enabled: true,
    endpoint: 'https://cuepid-maintenance.microcms.io/api/v1/status',
    targetName: 'influencer',
    apiKey: '2bda032f595c4ca789c12463e74b5f864ec3',
  },

  sentry: {
    enabled: true,
    debug: false,
    environment: 'production',
    dsn: 'https://e6668def7a6d4c83bb4c47acfe677f91@o206970.ingest.sentry.io/5379454',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://influencer.cuepid.net'],
        routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.4,
  },
};
