import { Performance } from '../performance';

export class InstagramPerformance extends Performance {
  instagram_account_id!: number;
  follower!: number;
  followee!: number;
  posting!: number;

  average_like!: number;
  average_comment!: number;
  average_impression!: number;
  average_reach!: number;
  average_saved!: number;

  get engagement(): number {
    if (!this.average_impression) {
      return 0;
    }
    return (
      (this.average_like + this.average_comment + this.average_saved) /
      this.average_impression
    );
  }

  get comment_rate(): number {
    if (!this.average_impression) {
      return 0;
    }
    return this.average_comment / this.average_impression;
  }

  get like_rate(): number {
    if (!this.average_impression) {
      return 0;
    }
    return this.average_like / this.average_impression;
  }
}
