import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '@cuepid/influencer/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output()
  closeSideBar = new EventEmitter();

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  signOut() {
    this.authService.signOut();
  }
}
