import { FactoryProvider, InjectionToken } from '@angular/core';

export const _window = () => window;

export const WINDOW = new InjectionToken<Window>('WindowToken');
export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: _window,
  deps: []
};
