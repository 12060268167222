import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AdministrationService } from '@cuepid/core';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export abstract class CheckMaintenanceModeGuard
  implements CanActivate, CanActivateChild
{
  protected constructor(
    protected readonly administrationService: AdministrationService,
    private readonly router: Router
  ) {}

  canActivate(): boolean | Observable<boolean> {
    return this.checkInMaintenance();
  }

  canActivateChild(): boolean | Observable<boolean> {
    return this.checkInMaintenance();
  }

  private checkInMaintenance(): boolean | Observable<boolean> {
    if (!this.administrationService.enableMaintenanceMode()) {
      return true;
    }

    return this.administrationService.getMaintenanceStatus().pipe(
      mergeMap((status) => {
        if (!status) {
          return of(true);
        }

        if (!status.in_maintenance) {
          return of(true);
        }

        return from(
          this.router.navigate(this.resolveMaintenancePagePath(), {
            state: status,
          })
        );
      })
    );
  }

  abstract resolveMaintenancePagePath(): string[];
}
