import { Component, OnInit } from '@angular/core';
import { InfluencerService } from '@cuepid/influencer/core/services/influencer.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  hasUnread = false;
  subscription?: Subscription;

  constructor(private readonly influencerService: InfluencerService, private readonly router: Router) {
    router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => /\/message\/\d+/.test(event.url)),
    ).subscribe(() => this.closeUnreadBadge());
  }

  ngOnInit(): void {
    this.subscription = this.influencerService.hasUnreadMessage().subscribe(hasUnread => this.hasUnread = !!hasUnread);
  }

  private closeUnreadBadge(): void {
    this.subscription?.unsubscribe();
    this.hasUnread = false;
  }
}
