import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AdministrationService, MaintenanceCondition } from '@cuepid/core';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceStatusResolver
  implements Resolve<MaintenanceCondition>
{
  constructor(
    private readonly administrationService: AdministrationService,
    private readonly router: Router
  ) {}

  resolve(): Observable<MaintenanceCondition> {
    if (!this.administrationService.enableMaintenanceMode()) {
      this.navigateToTop();
      return EMPTY;
    }

    const state = this.router.getCurrentNavigation()?.extras.state;
    if (!!state && state instanceof MaintenanceCondition) {
      return of(state);
    }

    return this.administrationService.getMaintenanceStatus().pipe(
      mergeMap((status) => {
        if (!status || !status.in_maintenance) {
          this.navigateToTop();
          return EMPTY;
        }
        return of(status);
      })
    );
  }

  private navigateToTop(): void {
    this.router.navigate(['/']);
  }
}
