import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@cuepid/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    analytics: Angulartics2GoogleTagManager
  ) {
    analytics.startTracking();
  }

  @HostListener('window:resize')
  onViewResize() {
    this.setViewHeightProperty();
    this.setViewWidthProperty();
  }

  ngOnInit(): void {
    this.setViewHeightProperty();
    this.setViewWidthProperty();
  }

  private setViewHeightProperty() {
    const displayHeight = this.window.innerHeight;
    this.document.documentElement.style.setProperty(
      '--vh-base',
      `${displayHeight / 100}px`
    );
  }

  private setViewWidthProperty() {
    let displayWidth = this.window.visualViewport.width;
    if (displayWidth > 390) {
      displayWidth = 390;
    }
    this.document.documentElement.style.setProperty(
      '--vw-base',
      `${displayWidth / 100}px`
    );
  }
}
