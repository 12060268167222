import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '@cuepid/influencer/shared/directives/shared-directives.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { EllipsisModule } from 'ngx-ellipsis';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgArrayPipesModule } from 'ngx-pipes';
import { CampaignListRowComponent } from './campaign-list-row/campaign-list-row.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InputReportMessageComponent } from './input-report-message/input-report-message.component';
import { LoadIndicatorComponent } from './load-indicator/load-indicator.component';
import { ProfileInputRateComponent } from './profile-input-rate/profile-input-rate.component';
import { InstagramMediaFetchedErrorComponent } from './report/instagram-media-fetched-error/instagram-media-fetched-error.component';
import { InstagramNeedReconnectionComponent } from './report/instagram-need-reconnection/instagram-need-reconnection.component';
import { RootLayoutComponent } from './root-layout/root-layout.component';
import { SelectInstagramPostComponent } from './select-instagram-post/select-instagram-post.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [
    RootLayoutComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    CampaignListRowComponent,
    ConfirmDialogComponent,
    LoadIndicatorComponent,
    SelectInstagramPostComponent,
    InputReportMessageComponent,
    InstagramNeedReconnectionComponent,
    InstagramMediaFetchedErrorComponent,
    ProfileInputRateComponent,
  ],
  exports: [
    CampaignListRowComponent,
    RootLayoutComponent,
    FooterComponent,
    SelectInstagramPostComponent,
    InputReportMessageComponent,
    InstagramNeedReconnectionComponent,
    InstagramMediaFetchedErrorComponent,
    ProfileInputRateComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    FlexModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    LazyLoadImageModule,
    MatBadgeModule,
    EllipsisModule,
    MatInputModule,
    ReactiveFormsModule,
    NgArrayPipesModule,
    PerfectScrollbarModule,
    MatChipsModule,
    SharedDirectivesModule,
  ],
})
export class SharedComponentsModule {}
