import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WINDOW } from '../factories/window.factory';

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectGuard implements CanActivate {

  constructor(@Inject(WINDOW) private readonly window: Window) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const {link} = route.data;
    if (!link) {
      return false;
    }
    this.window.location.href = link;
    return true;
  }

}
