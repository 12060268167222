<div class="sidenav-wrapper" [style.background-color]="background">
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over">
      <app-sidebar (closeSideBar)="sidenav.close()"></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <div *ngIf="hamburgerMenu" class="open-menu" (click)="sidenav.open()">
        <div class="material-icons-outlined icon-hamburger">menu</div>
      </div>
      <app-header *ngIf="header" [title]="heading!"></app-header>
      <div class="router-wrapper" [class.use-footer]="footer">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="footer"></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
