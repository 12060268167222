export class StringHelper {
  static countLength(value: string): number {
    // @ts-ignore
    if ('Segmenter' in Intl && !!Intl.Segmenter) {
      // @ts-ignore
      const segmenter = new Intl.Segmenter('ja-JP');
      return [...segmenter.segment(value)].length;
    }

    // 絵文字修飾子などが存在する場合、正確に計算できない
    return [...value].length;
  }
}
