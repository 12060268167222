import { Type } from 'class-transformer';
import { SocialAccount } from '../social-account';
import { InstagramPerformance } from './instagram-performance';

export class InstagramInsightSnapshot {
  audience?: {
    audience_gender_age: any;
    audience_country: any;
    audience_city: any;
  };
  pr_media: { total: number; pr_media_count: number } | null = null;
  collected_at?: string;
}

export class InstagramAccount extends SocialAccount<InstagramPerformance> {
  @Type(() => InstagramPerformance)
  performances: InstagramPerformance[] = [];
  @Type(() => InstagramPerformance)
  latest_performance?: InstagramPerformance;

  id!: number;
  username!: string;
  biography?: string;
  insight_snapshot?: InstagramInsightSnapshot;
  need_reconnect?: boolean;
}
