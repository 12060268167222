import { fromEvent, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export class BlobConverter {
  static fromDataURL(value: string): Blob | null {
    const binary = atob(value.split(',')[1]);

    const typeMatch = value.match(/(:)([a-z\/]+)(;)/);
    if (!typeMatch) {
      return null;
    }
    const mimeType = typeMatch[2];

    const content = new Uint8Array(binary.length);
    for (let i = 0; content.length > i; i++) {
      content[i] = binary.charCodeAt(i);
    }

    return new Blob([content], { type: mimeType });
  }

  static toBase64String(blob: Blob): Observable<string> {
    const reader = new FileReader();
    const stream = fromEvent<ProgressEvent<FileReader>>(reader, 'load').pipe(
      // Blob → ArrayBuffer
      map((event) => event.target?.result as ArrayBuffer),
      // ArrayBuffer → TypedArray
      map((arrayBuffer) => new Uint8Array(arrayBuffer)),
      // Uint8Array → BinaryString
      map((typedArray) => {
        const buffer = 1024;
        let binaryString = '';
        for (let i = 0; i < typedArray.length; i += buffer) {
          binaryString += String.fromCharCode.apply(
            null,
            // @ts-ignore
            typedArray.slice(i, i + buffer)
          );
        }
        return binaryString;
      }),
      // BinaryString → Base64String
      map((binaryString) => btoa(binaryString))
    );

    if (blob instanceof Blob) {
      reader.readAsArrayBuffer(blob);
    }

    return stream;
  }

  static fromBase64String(
    value: string,
    mimeType: string = 'image/png'
  ): Observable<Blob> {
    return of(value).pipe(
      // Base64String → BinaryString
      map((base64String) => atob(base64String)),
      // BinaryString → Uint8Array
      map((binaryString) => {
        const charCodeArr = [];
        for (let i = 0, len = binaryString.length; i < len; ++i) {
          const code = binaryString.charCodeAt(i);
          if (code < 0 || 255 < code) {
            throw new Error('Invalid range detected of character code.');
          }
          charCodeArr[i] = code;
        }
        return Uint8Array.from(charCodeArr);
      }),
      // TypedArray → Blob
      map((typedArray) => new Blob([typedArray], { type: mimeType }))
    );
  }

  static fromUrl(
    url: string,
    outputFormat: string = 'image/png'
  ): Observable<Blob | null> {
    const img = new Image();

    img.crossOrigin = 'Anonymous';

    const stream = fromEvent<Event>(img, 'load').pipe(
      map((_) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx?.drawImage(img, 0, 0);
        return canvas.toDataURL(outputFormat);
      }),
      map((dataUrl) => this.fromDataURL(dataUrl))
    );

    img.src = url;

    return stream;
  }

  static resize(
    blob: Blob,
    maxSize: number = 1200,
    type: string = 'image/jpeg',
    quality: number = 0.9
  ): Observable<Blob | null> {
    const reader = new FileReader();
    const stream = fromEvent<ProgressEvent<FileReader>>(reader, 'load').pipe(
      // Blob → DataURL
      map((event) => event.target?.result as string),
      // DataURL into Canvas
      mergeMap((dataUrl: string) => {
        const image = new Image();
        const _stream = fromEvent<Event>(image, 'load').pipe(
          mergeMap((_) => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;

            // @ts-ignore
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);

            return new Observable<Blob | null>((observer) => {
              canvas.toBlob(
                (b) => {
                  observer.next(b);
                  observer.complete();
                },
                type,
                quality
              );
            });
          })
        );

        image.src = dataUrl;

        return _stream;
      })
    );

    reader.readAsDataURL(blob);

    return stream;
  }

  // @see https://stackoverflow.com/questions/57976898/how-to-get-mime-type-from-base-64-string
  static detectMimeTypeFromBase64(base64: string): string {
    /* eslint-disable */
    const signatures: { [key: string]: string } = {
      R0lGODdh: 'image/gif',
      R0lGODlh: 'image/gif',
      iVBORw0KGgo: 'image/png',
      '/9j/': 'image/jpg',
    };
    /* eslint-disable */

    for (const s in signatures) {
      if (base64.indexOf(s) === 0) {
        return signatures[s];
      }
    }

    return '';
  }
}
