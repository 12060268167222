import { Component, OnChanges } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, tap } from 'rxjs/operators';

interface RootLayoutConfig {
  hamburgerMenu?: boolean;
  header?: boolean;
  title?: string | null;
  heading?: string | null;
  footer?: boolean;
  background?: string | null;
}

@Component({
  selector: 'app-root-layout',
  templateUrl: './root-layout.component.html',
  styleUrls: ['./root-layout.component.scss']
})
export class RootLayoutComponent implements OnChanges {

  hamburgerMenu?: boolean;
  header?: boolean;
  title?: string | null;
  heading?: string | null;
  footer?: boolean;
  background?: string | null;

  constructor(route: ActivatedRoute, headTitle: Title, router: Router) {
    router.events.pipe(
      filter((event: any) => event instanceof ActivationEnd),
      map((event: ActivationEnd) => event.snapshot),
      filter((snapshot: ActivatedRouteSnapshot) => !!snapshot.component && snapshot.component !== RootLayoutComponent),
      map((snapshot: ActivatedRouteSnapshot) => snapshot.data),
      map(data => Object.assign(this.defaultProperties, data) as RootLayoutConfig),
      tap(data => headTitle.setTitle(!!data?.title ? `${data.title} | Cuepid` : 'Cuepid'))
    ).subscribe(data => Object.entries(data).forEach(([key, value]) => this[key as (keyof RootLayoutConfig)] = value));
  }

  get defaultProperties(): RootLayoutConfig {
    return {hamburgerMenu: true, header: true, title: null, heading: null, footer: true, background: null};
  }

  ngOnChanges(): void {
  }

}
