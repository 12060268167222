import { Pipe, PipeTransform } from '@angular/core';
import { MediumService } from '../services/medium.service';

@Pipe({
  name: 'rewardType'
})
export class RewardTypePipe implements PipeTransform {

  constructor(private mediumService: MediumService) {
  }

  transform(value: number | undefined, mediumId: number | undefined, property?: string): string | null | undefined {
    if (!value || !mediumId) {
      return null;
    }

    const rewardTypes = this.mediumService.getRewardTypes(mediumId);
    if (rewardTypes === null) {
      return null;
    }

    if (!property) {
      return rewardTypes.find(type => type.code === value)?.name;
    }

    return (rewardTypes.find(type => type.code === value) as {[key: string]: any})[property];
  }
}
