export class ChildrenOption {
  static readonly EXISTS = new ChildrenOption(1, '有り');
  static readonly NOT_EXISTS = new ChildrenOption(2, '無し');

  constructor(public readonly code: number, public readonly name: string) {
  }

  static all(): ChildrenOption[] {
    return [
      ChildrenOption.EXISTS,
      ChildrenOption.NOT_EXISTS,
    ];
  }

  static fromCode(code: number): ChildrenOption | undefined {
    return this.all().find(option => option.code === code);
  }
}
