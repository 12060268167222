import { ValidatorFn } from '@angular/forms';
import { StringHelper } from '../helpers/string.helper';

export class StringValidator {
  static maxLength(maxLength: number): ValidatorFn {
    return (control) => {
      const value = control.value;

      if (value == null) {
        return null;
      }

      const length = StringHelper.countLength(value);
      return length > maxLength
        ? { maxlength: { requiredLength: maxLength, actualLength: length } }
        : null;
    };
  }
}
