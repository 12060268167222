/// <reference types="@types/google.maps" />

import { Inject, Injectable } from '@angular/core';
import { Loader } from '@googlemaps/loader';
import { EMPTY, from, Observable } from 'rxjs';
import { GOOGLE_MAP_API_KEY } from '../cuepid-core.module';
import { DOCUMENT } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class GoogleMapService {

  private loaded: Promise<void>;

  constructor(@Inject(GOOGLE_MAP_API_KEY) private apiKey: string, @Inject(DOCUMENT) private document: Document) {
    const loader = new Loader({apiKey});
    this.loaded = loader.load();
  }

  getAddress(postcode: string): Observable<google.maps.GeocoderResult[]> {
    return from(this.loaded).pipe(
      map(() => new google.maps.Geocoder()),
      mergeMap(geocoder => geocoder.geocode({address: postcode}, null)),
      map(response => response.results),
    );
  }

  /**
   * 住所に即したGoogleMapを指定のエレメントに書き込む
   *
   * @param address
   * @param elementId
   */
  createMap(address: string | undefined, elementId: string): Observable<google.maps.Marker> {
    const element = this.document.querySelector(`#${elementId}`);
    if (!address || !element) {
      return EMPTY;
    }

    return from(this.loaded).pipe(
      map(() => new google.maps.Geocoder()),
      mergeMap(geocoder => geocoder.geocode({address}, null)),
      map(response => response.results),
      filter(results => results.length > 0),
      map(results => results[0]),
      map(result => {
        const {location} = result.geometry;
        const googleMap = new google.maps.Map(element, {
          center: location,
          zoom: 16,
          mapTypeControl: false,
          streetViewControl: false,
          scrollwheel: false,
        });
        return new google.maps.Marker({
          position: location,
          map: googleMap,
          icon: {
            url: '/assets/images/map_marker.png',
            scaledSize: new google.maps.Size(40.6, 53.8)
          }
        });
      }),
    );
  }
}
