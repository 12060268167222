import { Expose, Type } from 'class-transformer';
import { Channel } from './channel';
import { CompanyInfluencerViewHistory } from './company-influencer-view-history';
import { CompanyMetadata } from './company-metadata';
import { ContractPlan } from './contract-plan';

export class TutorialSetting {
  completed = false;
}

export class CompanySettings {
  @Type(() => TutorialSetting)
  tutorial?: TutorialSetting;
}

export type UseApplication = Pick<
  Company,
  'id' | 'email' | 'name' | 'contact' | 'phone_number' | 'address' | 'website'
>;

export class Company {
  @Expose({ name: 'has_confirming_agreement' })
  hasConfirmingAgreement!: boolean;
  @Expose({ name: 'has_contract_change_request' })
  hasContractChangeRequest!: boolean;
  @Expose({ name: 'agreement_signed' })
  agreementSigned = false;
  @Expose({ name: 'reserved_plan' })
  reservedPlan: ContractPlan | null = null;

  @Type(() => ContractPlan)
  current_plan!: ContractPlan;
  @Type(() => ContractPlan)
  contract_plans: ContractPlan[] = [];
  @Type(() => CompanySettings)
  settings: CompanySettings | null = null;
  @Type(() => CompanyMetadata)
  metadata: CompanyMetadata | null = null;
  channels: Channel[] = [];

  id!: number;
  name!: string;
  email!: string;
  contact!: string;
  phone_number!: string;
  website?: string;

  department?: string;
  icon_image_name?: string;
  introduction?: string;
  philosophy?: string;
  address?: string;

  // API生成プロパティ
  icon_url?: string;
  offer_sent_count = 0;
  entry_accepted_count = 0;
  created_campaign_count = 0;
  influencer_view_histories: CompanyInfluencerViewHistory[] = [];

  private readonly MAX_VIEW_COUNT = 3;

  isReachedToOfferLimit(): boolean {
    return (
      this.offer_sent_count >=
      this.current_plan?.current_limitation?.send_offer_limit
    );
  }

  isReachedToEntryLimit(): boolean {
    return (
      this.entry_accepted_count >=
      this.current_plan?.current_limitation?.accept_entry_limit
    );
  }

  getRemainOfferCount(): number {
    return (
      this.current_plan?.current_limitation?.send_offer_limit -
      this.offer_sent_count
    );
  }

  getRemainEntryCount(): number {
    return (
      this.current_plan?.current_limitation?.accept_entry_limit -
      this.entry_accepted_count
    );
  }

  /**
   * FIXME フリープランの場合でも、更新しようとした時に同一IDのキャンペーンを更新できなくなる可能性が残るため
   */
  canCreateCampaign(): boolean {
    if (this.current_plan?.id !== ContractPlan.ID.FREE_PLAN) {
      return true;
    }

    return this.created_campaign_count === 0;
  }

  countRemainingViewDeposit(): undefined | number {
    if (!this.influencer_view_histories) {
      return undefined;
    }
    return this.MAX_VIEW_COUNT - this.influencer_view_histories.length;
  }
}
