import { Expose, Type } from 'class-transformer';
import { addMonths, endOfMonth } from 'date-fns';

export class Contract {
  @Type(() => Date)
  start_date!: Date;
  @Type(() => Date)
  end_date!: Date;

  company_id!: number;
  contract_plan_id!: number;

  static calculatePlanPeriod(
    plan: ContractPlan,
    timing: Contract.StartTiming
  ): number {
    return timing === Contract.StartTiming.NOW ? plan.period - 1 : plan.period;
  }

  static calculateEndDate(date: Date, period: Contract.StartTiming): Date {
    return endOfMonth(addMonths(date, period));
  }
}

class ContractPlanLimitation {
  price!: number;
  accept_entry_limit!: number;
  send_offer_limit!: number;
}

export class ContractPlan {
  @Expose({ name: 'is_free_plan' })
  isFreePlan!: boolean;
  @Expose({ name: 'is_extendable' })
  _isExtendable!: boolean;

  @Type(() => ContractPlanLimitation)
  current_limitation!: ContractPlanLimitation;
  @Type(() => Contract)
  pivot!: Contract;

  /**
   * 割引中のベーシックプランは見かけ上は通常のベーシックプランと同一なため、
   * 延長可能なものとして扱う
   */
  get isExtendable(): boolean {
    return (
      this._isExtendable || this.id === ContractPlan.ID.BASIC_PLAN_DISCOUNT
    );
  }

  id!: number;
  name!: string;
  priority!: number;
  period!: number;

  price?: number;
  accept_entry_limit?: number;
  send_offer_limit?: number;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ContractPlan {
  export enum ID {
    FREE_PLAN = 1,
    CAMPAIGN_PLAN = 5,
    SHORT_BASIC_PLAN,
    BASIC_PLAN,
    BASIC_PLAN_DISCOUNT,
    PREMIUM_PLAN,
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Contract {
  export enum StartTiming {
    NOW,
    NEXT_MONTH,
  }
}
