import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaintenanceCondition } from '@cuepid/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
  status: MaintenanceCondition;

  constructor(route: ActivatedRoute) {
    this.status = route.snapshot.data.status;
  }
}
