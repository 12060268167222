import { Routes } from '@angular/router';
import { ExternalRedirectGuard } from '@cuepid/core';
import { CheckMaintenanceModeGuard } from '@cuepid/influencer/core/guards/check-maintenance-mode.guard';
import { MaintenanceStatusResolver } from '@cuepid/influencer/core/resolvers/maintenance-status.resolver';
import { MaintenanceComponent } from '@cuepid/influencer/pages/maintenance/maintenance.component';
import { RootLayoutComponent } from '@cuepid/influencer/shared/components/root-layout/root-layout.component';

export const appRouterConfig: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/index/index.module').then((m) => m.IndexModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'dashboard',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'campaign',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/campaign/campaign.module').then((m) => m.CampaignModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'message',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/message/message.module').then((m) => m.MessageModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'user',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'information',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/information/information.module').then(
        (m) => m.InformationModule
      ),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'support',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'report',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/report/report.module').then((m) => m.ReportModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'setting',
    component: RootLayoutComponent,
    loadChildren: () =>
      import('./pages/setting/setting.module').then((m) => m.SettingModule),
    canActivateChild: [CheckMaintenanceModeGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    resolve: {
      status: MaintenanceStatusResolver,
    },
  },
  {
    path: 'lp',
    component: ExternalRedirectGuard,
    canActivate: [ExternalRedirectGuard],
    data: {
      link: 'https://influencer.cuepid.net/lp/',
    },
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
