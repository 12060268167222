export class Sex {
  static readonly NOT_KNOWN = new Sex(0, '不明');
  static readonly MALE = new Sex(1, '男性');
  static readonly FEMALE = new Sex(2, '女性');
  static readonly NOT_APPLICABLE = new Sex(9, '適用不能');

  constructor(public readonly code: number, public readonly name: string) {
  }

  static all(): Sex[] {
    return [
      this.NOT_KNOWN,
      this.MALE,
      this.FEMALE,
      this.NOT_APPLICABLE
    ];
  }

  static fromCode(code: number): Sex | undefined {
    return this.all().find(sex => sex.code === code);
  }
}
