export class BankAccountType {
  private constructor(readonly name: string, readonly code: number) {
  }

  static all(): BankAccountType[] {
    return [
      new BankAccountType('普通', 1),
      new BankAccountType('当座', 2),
    ];
  }
}
